<template>
  <div class="stationDetail-container">
    <a-page-header title="門市配置">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <div class="edit-box">
      <a-row :gutter="[16, 10]">
        <a-col :span="24">
          <a-form-item
            label="健康小站編號"
            :colon="false"
            :labelCol="{ span: 4 }"
            labelAlign="left"
          >
            {{ stationID }}
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            label="配置門市"
            :colon="false"
            :labelCol="{ span: 4 }"
            labelAlign="left"
          >
            <a-select
              v-model:value="form.storeID"
              showSearch
              optionFilterProp="label"
              style="width: 300px"
            >
              <a-select-option value="">未配置</a-select-option>
              <a-select-option
                v-for="item in storeList"
                :label="item.store_name"
                :key="item.store_name"
                :value="item.store_id"
              >
                {{ item.store_name }}
              </a-select-option>
            </a-select>
            <a-button
              ghost
              type="primary"
              style="margin-left: 10px"
              @click="handleReset"
            >
              重新配置
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
    <a-space>
      <a-button style="margin-left: 10px" @click="cancel">取消</a-button>
      <a-button type="primary" @click="save" :disabled="!storeChanged">
        儲存
      </a-button>
    </a-space>
  </div>
</template>

<script>
  import { reactive, onMounted, watch, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { notification } from 'ant-design-vue'
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'
  import { getStation, setStation, delStation } from '@/api/store'
  export default {
    components: {
      QuestionCircleOutlined,
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const form = reactive({
        storeID: '',
      })
      const stationID = route.params.id
      const storeList = store.getters['store/storeList']
      const storeChanged = ref(false) // 是否有改變門市
      let storeIdOrg

      onMounted(async () => {
        try {
          const { data, status } = await getStation()
          if (![200, 201].includes(status)) throw 'api error'
          if (!data.ok) throw 'api error'
          form.storeID = parseInt(
            data.data.find((item) => item.stationID == stationID).store.storeID
          )
          form.storeID = isNaN(form.storeID) ? '' : form.storeID
          storeIdOrg = form.storeID
        } catch (e) {
          console.log(e)
          notification.error({
            message: '查詢失敗',
          })
        }
      })

      const handleReset = () => {
        form.storeID = ''
      }

      const save = async () => {
        try {
          if (form.storeID.toString() == '') {
            const { data } = await delStation({
              hubCode: stationID,
            })
            if (!data.ok) throw '移除配置失敗'
            notification.success({
              message: '移除配置成功',
            })
          } else {
            const { data } = await setStation({
              locationCode: form.storeID.toString(),
              locationTitle: storeList.find(
                (item) => item.store_id == form.storeID
              ).store_name,
              hubCode: stationID,
              note: '',
              force: true,
            })
            if (!data.ok) throw data.result
            notification.success({
              message: '門市配置成功',
            })
          }

          router.go(-1)
        } catch (error) {
          notification.error({
            message: error,
          })
        }
      }
      const cancel = () => {
        router.go(-1)
      }

      watch(
        () => form,
        () => {
          storeChanged.value = form.storeID !== storeIdOrg
        },
        {
          deep: true,
        }
      )

      return {
        stationID,
        storeChanged,
        storeList,
        form,
        handleReset,
        save,
        cancel,
      }
    },
  }
</script>

<style lang="less">
  .edit-box {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
  }
  .ant-space {
    float: right;
    margin: 12px;
  }
</style>
