import { render } from "./_id.vue?vue&type=template&id=da138a14"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"

import "./_id.vue?vue&type=style&index=0&id=da138a14&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "da138a14"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('da138a14', script)) {
    api.reload('da138a14', script)
  }
  
  module.hot.accept("./_id.vue?vue&type=template&id=da138a14", () => {
    api.rerender('da138a14', render)
  })

}

script.__file = "src/views/setting/station/_id.vue"

export default script